import React, {useState} from 'react';

function Dropdown() {
    // Array of objects containing our fruit data
    const countries = [
      
      { label: "Kenya", value: "Kenya", id: 1 },
      { label: "India", value: "India", id: 2 },
      { label: "Botswana", value: "Botswana", id: 3 }
  ]
  
  // Using state to keep track of what the selected fruit is
  const [countryReg, setCountryReg] = useState('')
  
  // Using this function to update the state of fruit
  // whenever a new option is selected from the dropdown
  const handleCountryChange = (e) => {
    setCountryReg(e.target.value)
    
  }
  
    return (
      <div className="App">
      {/* Displaying the value of fruit */}
      {countryReg}
      <br />
  
      {/* Creating our dropdown and passing it the handleFruitChange 
        so that every time a new choice is selected, our fruit state 
        updates and renders an emoji of the fruit.
      */}
      <select onChange={handleCountryChange}> 
        <option value=""> -- Select a Country -- </option>
              {/* Mapping through each fruit object in our fruits array
            and returning an option element with the appropriate attributes / values.
           */}
        {countries.map((countryReg) => <option value={countryReg.value}>{countryReg.label}</option>)}
      </select>
    
      </div>
    );
  }
  
  export default Dropdown;
  
import React, { useState } from "react";
import { auth, googleProvider } from './firebase';
import { createUserWithEmailAndPassword,signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

import { Button, TextField, Box, Typography, Container, Alert } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';


const Signup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [notice, setNotice] = useState("");

    const signupWithUsernameAndPassword = async (e) => {
        e.preventDefault();

        if (password === confirmPassword) {
            try {
                await createUserWithEmailAndPassword(auth, email, password);
                navigate("../profile");
            } catch {
                setNotice("Sorry, something went wrong. Please try again.");
            }     
        } else {
            setNotice("Passwords don't match. Please try again.");
        }
    };

    const handleGoogleSignIn = async () => {
        try {
          await signInWithPopup(auth, googleProvider);
          navigate('../profile'); // Redirect to home page after successful sign-up
        } catch (error) {
            setNotice(error.message);
        }
      };

    return(
        // <div className = "container">
        //     <div className = "row justify-content-center">
        //         <form className = "col-md-4 mt-3 pt-3 pb-3">
        //             { "" !== notice &&
        //                 <div className = "alert alert-warning" role = "alert">
        //                     { notice }    
        //                 </div>
        //             }
        //             <div className = "form-floating mb-3">
        //                 <input id = "signupEmail" type = "email" className = "form-control" aria-describedby = "emailHelp" placeholder = "name@example.com" value = { email } onChange = { (e) => setEmail(e.target.value) }></input>
        //                 <label htmlFor = "signupEmail" className = "form-label">Enter an email address for your username</label>
        //             </div>
        //             <div className = "form-floating mb-3">
        //                 <input id = "signupPassword" type = "password" className = "form-control" placeholder = "Password" value = { password } onChange = { (e) => setPassword(e.target.value) }></input>
        //                 <label htmlFor = "signupPassword" className = "form-label">Password</label>
        //             </div>
        //             <div className = "form-floating mb-3">
        //                 <input id = "confirmPassword" type = "password" className = "form-control" placeholder = "Confirm Password" value = { confirmPassword } onChange = { (e) => setConfirmPassword(e.target.value) }></input>
        //                 <label htmlFor = "confirmPassword" className = "form-label">Confirm Password</label>
        //             </div>                    
        //             <div className = "d-grid">
        //                 <button type = "submit" className = "btn btn-primary pt-3 pb-3" onClick = {(e) => signupWithUsernameAndPassword(e)}>Signup</button>
        //             </div>
        //             <Button
        //   fullWidth
        //   variant="outlined"
        //   startIcon={<GoogleIcon />}
        //   onClick={handleGoogleSignIn}
        //   sx={{ mt: 2 }}
        // >
        //   Sign Up with Google
        // </Button>
        //             <div className = "mt-3 text-center">
        //                 <span>Go back to login? <Link to = "/">Click here.</Link></span>
        //             </div>                    
        //         </form>
        //     </div>
        // </div>
        <Container maxWidth="sm">
        <Box mt={4}>
          {notice && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {notice}
            </Alert>
          )}
          <form onSubmit={signupWithUsernameAndPassword}>
            <TextField
              label="Enter an email address for your username"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Sign Up
            </Button>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={{ mt: 2 }}
            >
              Sign Up with Google
            </Button>
          </form>
          <Box mt={3} textAlign="center">
            <Typography>
              Go back to login?{' '}
              <Link to="/" style={{ textDecoration: 'none', color: '#3f51b5' }}>
                Click here.
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    )
}

export default Signup
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import React, { useState } from "react";

import { TextField } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// const querySnapshot = await getDocs(collection(db, "bond_list"));

// querySnapshot.forEach((doc) => {
//  // console.log(`${doc.id} => ${doc.data()}`);
//   console.log(`${doc.id} => ${doc.data().interest_rate}`);
// });



const TenureSelect = ({ data, setData }) => {

  // = ({ data }) => {
  const CouponPayments = () => {

    //  const { selected_bond } = data;

    if (data.selected_bond && data.selected_bond.rem_coupons) {
      // if (data.selected_bond) {
      return <div>

        <TableContainer component={Paper}>
                  <Table  aria-label="simple table">
                      <TableHead>
                          <TableRow>
                              <TableCell>There are Remaining {data.selected_bond.rem_coupons.length} Payments on this bond</TableCell>
                              {/* <TableCell align="right">Calories</TableCell>
                              <TableCell align="right">Fat&nbsp;(g)</TableCell>
                              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                              <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {data.selected_bond.rem_coupons.map((row) => (

                              <TableRow

                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                  <TableCell component="th" scope="row">
                                   
                                      {new Date(row * 1000).toDateString()};

                                  </TableCell>
                                  {/* <TableCell align="right">{row.fat}</TableCell>
                                  <TableCell align="right">{row.carbs}</TableCell>
                                  <TableCell align="right">{row.protein}</TableCell> */}
                              </TableRow>
                           ))} 
                      </TableBody>
                  </Table>
              </TableContainer>
      </div>



        ;
    } else {
      // return <button>not there</button>;
      return '';
    }
  };


  let bond_list = data.bond_list;

  //let filteredUsers = users.filter(user => user.age > 40 && user.occupation === 'programmer');
  // let filteredUsers = data.bond_list.filter(user => user.label === "5" );
  // console.log(filteredUsers[0]);

  const handleChange = (event) => {
    //console.log('bond selected label:' + event.target.label)
    console.log('bond selected value:' + event.target.value)

    // filter the bond list to get the bond year and put in the bond_label variable
    let filteredBond = data.bond_list.filter(user => user.value === event.target.value);
    console.log("bond object:" + JSON.stringify(filteredBond[0]));
    console.log("bond_label:" + filteredBond[0].value);


    let bond_value = filteredBond[0].value
    let bond_label = filteredBond[0].label
    console.log("bond_label:" + bond_label);

    //remaining coupon list
    console.log("remaining coupons:" + JSON.stringify(filteredBond[0].rem_coupons));


    var int_rate = 0.14

    if (event.target.value == 2) {
      int_rate = 0.14
    } else if (event.target.value == 3) {
      int_rate = 0.135
    } else if (event.target.value == 3) {
      int_rate = 0.14125
    } else if (event.target.value == 5) {
      int_rate = 0.14125
    } else if (event.target.value == 10) {
      int_rate = 0.14375
    } else if (event.target.value == 15) {
      int_rate = 0.16
    } else if (event.target.value == 20) {
      int_rate = 0.16
    }

    //console.log('final int_rate:' + int_rate)

    setData({
      ...data, loanTerm: bond_value,
      interestRate: event.target.value,
      bond_years: bond_label,
      selected_bond: filteredBond[0]
    });

    // setData({
    //   ...data, loanTerm: event.target.value,
    //   interestRate: event.target.value,
    // });

    // setData({
    //   ...data, loanTerm: event.target.value,
    //   interestRate: int_rate
    // });


    // setData({
    //   ...data, loanTerm: event.target.label,
    //   interestRate: event.target.value
    // });
  };

  const [loanAmount, setEmail] = useState("")

  // const setEmail = (event) => {
  //   console.log('bond selected label:' + event.target.label)
  //   console.log('bond selected value:' + event.target.value)

  // }

  return (

    <div>

      <FormControl fullWidth>
        {/* <InputLabel id="demo-simple-select-label">Bond</InputLabel> */}

        <TextField
          label="Amount Deposited"
          //onChange={e => setEmail(e.target.value)}
          // onChange={e => setEmail(e.target.value)}
          onChange={e => setData({
            ...data, loanAmount: e.target.value,
          })}
          required
          variant="outlined"
          color="secondary"
          type="email"
          // sx={{ mb: 3 }}
          fullWidth
          value={data.loanAmount}
          default = "1000"
        // error={emailError}
        />

        <br />

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data.loanTerm}
          label="Years"
          defaultValue={0}
          onChange={handleChange}
        >
          {/* <MenuItem value={2}> 2 years</MenuItem>
        <MenuItem value={3}> 3 years</MenuItem>
        <MenuItem value={5}> 5 years</MenuItem>
        <MenuItem value={10}>10 years</MenuItem>
        <MenuItem value={15}>15 years</MenuItem>
        <MenuItem value={20}>20 years</MenuItem> */}

          {/* {countries.map((countryReg) => <option value={countryReg.value}>{countryReg.label}</option>)} */}

          {bond_list.map((bond) => <MenuItem value={bond.value}> {bond.label}</MenuItem>)}
        </Select>

        <CouponPayments></CouponPayments>
      </FormControl>
    </div>

  );
};

export default TenureSelect;
import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    '01 Jan, 2024',
    '2 year Bond',
    'Yes',
    'VISA ⠀•••• 3719',
    312.44,
  ),
  createData(
    1,
    '03 Mar, 2024',
    '5 year Bond',
    'Yes',
    'Wells Fargo•••• 2574',
    866.99,
  ),
  createData(2, '16 Mar, 2024', '5 year Bond', 'No', 'MC ⠀•••• 1253', 100.81),
  createData(
    3,
    '06 Jun, 2024',
    '10 year Bond',
    'Yes',
    'VISA ⠀•••• 3719',
    654.39,
  ),
  createData(
    4,
    '08 Aug, 2024',
    '5 year Bond',
    'Yes',
    'VISA ⠀•••• 3719',
    212.79,
  ),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders(props) {
  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Bond</TableCell>
            <TableCell>Paid Out</TableCell>
            <TableCell>Paid To</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.shipTo}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
              <TableCell align="right">{`$${row.amount}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link>
    </React.Fragment>
  );
}

//import * as React from 'react';
import React, { useState } from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import TenureSelect from "./TenureSelect";
import SliderSelect from "./SliderSelect";
import Result from "./Result";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const querySnapshot = await getDocs(collection(db, "bond_list"));

let options = [];


//LOAD COUPONS
querySnapshot.forEach((doc) => {
    // console.log(`${doc.id} => ${doc.data()}`);

    options.push({
        value: doc.data().value,
        label: doc.data().label,
        rem_coupons: doc.data().rem_coupons,
    });

    console.log(`${doc.id} => ${doc.data().value}`);
});


export default function Calculator() {

    const bond_list = [

        { label: "2 year", value: 0.14, id: 2, int_rate: .2 },
        { label: "5 year", value: 0.14125, id: 5, int_rate: .5 },
        { label: "20 year", value: 0.14, id: 20, int_rate: .2 },

    ]

    const [data, setData] = useState({
        homeValue: 3000,
        downPayment: 3000 * 0.2,
        loanAmount: 3000 * 0.8,
        loanTerm: 5,
        interestRate: 5,
        bond_list: options,
        bond_years: 0,
        selected_bond: ''
        // monthlyPayment: 0,
    });


    // = ({ data }) => {
        // const AuthButton = ({ data }) => {

        //     const { selected_bond } = data;
    
        //     if (selected_bond) {
        //         return <button>Logout</button>;
        //     } else {
        //         return <button>Login</button>;
        //     }
        // };
    

    return (
        <Container
            id="calculator"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >

            <Grid container spacing={5} alignItems="center">
                <Grid item xs={12} md={6}>

                    {/* <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dessert (100g serving)</TableCell>
                                    <TableCell align="right">Calories</TableCell>
                                    <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                    <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                    <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selected_bond.rem_coupons.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}


                    <TenureSelect data={data} setData={setData} />

                    <SliderSelect data={data} setData={setData} />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Result data={data} />
                </Grid>
            </Grid>
        </Container>
    );
}

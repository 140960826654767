import { useState } from "react";
import { auth, googleProvider } from "./firebase";
//import { signInWithEmailAndPassword } from "firebase/auth";
import { signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { Link, useNavigate } from "react-router-dom";
import { Button, TextField, Box, Typography, Container, Alert } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notice, setNotice] = useState("");

    const [success, setSuccess] = useState(null);
  
    const loginWithUsernameAndPassword = async (e) => {
      e.preventDefault();
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("../profile");
      } catch {
        setNotice("You entered a wrong username or password.");
      }
    };
  
    const handleGoogleSignIn = async () => {
      try {
        await signInWithPopup(auth, googleProvider);
        navigate('../profile');
      } catch (error) {
        setNotice(error.message);
      }
    };

    const handleForgotPassword = async () => {
        try {
          await sendPasswordResetEmail(auth, email);
          setSuccess('Password reset email sent!');
          setNotice(null);
        } catch (err) {
            setNotice(err.message);
          setSuccess(null);
        }
      };
    
  
    return (
      <Container maxWidth="sm">
        <Box mt={4}>
          {notice && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {notice}
            </Alert>
          )}

              {/* Success message */}
        {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
          <form onSubmit={loginWithUsernameAndPassword}>
            <TextField
              label="Email address"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>


          {/* Forgot Password Button */}
          <Button
            onClick={handleForgotPassword}
            sx={{ mt: 2 }}
          >
            Forgot Password?
          </Button>

            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={{ mt: 2 }}
            >
              Sign In with Google
            </Button>
          </form>
          <Box mt={3} textAlign="center">
            <Typography>
              Need to sign up for an account?{' '}
              <Link to="/signup" style={{ textDecoration: 'none', color: '#3f51b5' }}>
                Sign up here.
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  };
  
  export default Login;
//import logo from './logo.svg';
import borabond_logo_svg from './borabond_logo_svg.svg';
import './App.css';
import LandingPage from './LandingPage';

import Layout from "./Layout";
import Login from "./Login";
import Signup from "./Signup";
import Profile from "./Profile";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dropdown from './components/practice/Dropdown';

// function App() {
//   return (
//     <LandingPage />
//   );
// }

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout></Layout>}> */}
        <Route path="/"> 
          {/* <Route index element={<Login></Login>}></Route> */}
          <Route index element={<LandingPage></LandingPage>}></Route>
          <Route path="/login" element={<Login></Login>}></Route>
          <Route path="/signup" element={<Signup></Signup>} ></Route>
          <Route path="/profile" element={<Profile></Profile>}></Route>
          <Route path="/dropdown" element={<Dropdown></Dropdown>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;

import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../../firebase";
import Dashboard from "../dashboard/Dashboard"

function UserList({ user_uid }) {
  const [users, setUsers] = useState([]);

  console.log("search_string:"+'user_bonds/'+user_uid+'/bonds')

  useEffect(() => {
    const fetchUsers = async () => {
      //const usersCollectionRef = collection(db, 'user_bonds/X793plCOJUdwOw2Ej9PSK5780A03/bonds');
      const usersCollectionRef = collection(db, 'user_bonds/'+user_uid+'/bonds');
      const querySnapshot = await getDocs(usersCollectionRef);
      const usersData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(JSON.stringify(usersData))
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  return (
    <div>
      {/* <Dashboard/> */}
      {/* <h2>Current Bonds</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            <strong>Name:</strong> {user.name}
          </li>
        ))}
      </ul> */}
    </div>
  );
}

export default UserList;

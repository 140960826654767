import React from "react";
import { Stack, Typography } from "@mui/material";

// ChartJS.register(ArcElement, Tooltip, Legend);

const Result = ({ data }) => {

    const exchange_rate = 3700;
    const tax_rate = .8;
    const borabond_fee_rate = 0.075

    const { homeValue, loanAmount, loanTerm, interestRate, bond_years, selected_bond } = data;

    const totalLoanMonths = loanTerm * 12;
    const interestPerMonth = interestRate / 100 / 12;
    const monthlyPayment =
        (loanAmount *
            interestPerMonth *
            (1 + interestPerMonth) ** totalLoanMonths) /
        ((1 + interestPerMonth) ** totalLoanMonths - 1);

    const totalInterestGenerated = monthlyPayment * totalLoanMonths - loanAmount;

    // const pieChartData = {
    //     labels: ["Principle", "Interest"],
    //     datasets: [
    //         {
    //             label: "Ratio of Principle and Interest",
    //             data: [homeValue, totalInterestGenerated],
    //             backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
    //             borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    //const six_month_earnings = (interestRate / 2) * loanAmount
    //const six_month_earnings = (interestRate / 2) * 3700000
    console.log('loanAmount'+{loanAmount})

    var us_dollar_loanamount = loanAmount * exchange_rate;

    console.log('loanAmount * exchange_rate'+{us_dollar_loanamount})
    var six_month_earnings = (interestRate / 2) * (us_dollar_loanamount)

    var six_month_earnings_after_tax = six_month_earnings * tax_rate;

    var borabond_fee_amount =  ((six_month_earnings_after_tax)*(borabond_fee_rate))

    var six_month_earnings_after_after_tax_and_bora_fee = six_month_earnings_after_tax - borabond_fee_amount;

    var six_month_earnings_final_dollar = six_month_earnings_after_after_tax_and_bora_fee / exchange_rate;

    var annual_earnings = six_month_earnings_final_dollar * 2;

    var total_earnings = loanTerm * annual_earnings;

    var remaining_payments_length = selected_bond.rem_coupons ? selected_bond.rem_coupons.length : 0
    var total_earnings = six_month_earnings_after_after_tax_and_bora_fee * remaining_payments_length

    //const remaining_payments = 

    const RemainingPayments = () => {
 

        if (selected_bond && selected_bond.rem_coupons) {
            // if (data.selected_bond) {
            return <div>
                 Remaining Payments: {selected_bond.rem_coupons.length} 
            </div>

        }else {
            <div></div>
        }
    }

    return (
        <Stack gap={1}>
            <Typography textAlign="center" variant="h5">
                {/* Bond Term: {loanTerm} years  */}
                Bond Term: {bond_years} years 
                {/* <RemainingPayments/> */}
                <br/> Remaining Payments: {remaining_payments_length} 
                <br/> Interest Rate: {(interestRate*100).toFixed(2)}%
                <br/> Exchange Rate: {exchange_rate} 
                <br/> Tax rate: {((1-tax_rate)*100).toFixed(0)} %
                <br/> Bora bond fee: {(borabond_fee_rate * 100)} %

                
                
            </Typography>
            <Typography textAlign="center" variant="h5">
                Amount deposited: ${loanAmount}
            </Typography>
            {/* <Typography textAlign="center" variant="h5">
                Monthly Payment:  {monthlyPayment.toFixed(2)}
            </Typography> */}
            <Typography textAlign="center" variant="h5">
                6 Month Earning {six_month_earnings.toFixed(2)}
            </Typography>

            <Typography textAlign="center" variant="h5">
                6 Month Earning After Tax {(six_month_earnings_after_tax).toFixed(2)}
            </Typography>

            <Typography textAlign="center" variant="h5">
                6 Month Earning Borabond fee {borabond_fee_amount.toFixed(2)}
            </Typography>

            <Typography textAlign="center" variant="h5">
                6 Month Earning after Borabond fee UGX {six_month_earnings_after_after_tax_and_bora_fee.toFixed(2)}
            </Typography>

            <Typography textAlign="center" variant="h5">
                6 Month Earning after Borabond fee in USD $ {six_month_earnings_final_dollar.toFixed(2)}
            </Typography>

            <Typography textAlign="center" variant="h5">
            annual earnings $ {(annual_earnings).toFixed(2)}
            </Typography>

            <Typography textAlign="center" variant="h5">
            Total earnings UGX {(total_earnings).toFixed(2)}
            </Typography>

            <Typography textAlign="center" variant="h5">
            Total earnings $ {(total_earnings / exchange_rate).toFixed(2)}
            </Typography>
            

            {/* <Stack direction="row" justifyContent="center">
                <div>
                    <Pie data={pieChartData} />
                </div>
            </Stack> */}
        </Stack>
    );
};

export default Result;